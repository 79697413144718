import { deepMerge, BeforeTime, domainid } from 'utils/utils'
import { devParam } from 'utils/sqlQuery'
import axios from 'utils/aioxs'
import Jons from 'utils/dataset.json'

//默认配置参数
const ProPara = (params) => {
  //数据处理，这个地方的参数只是最初的结构
  let env = process.env.NODE_ENV
  if (env == 'development') {
    //开发环境,返回sql
    let dataset = Jons[params.application][params.datasource][params.name]
    let param = Object.assign({}, devParam(params), {
      query: dataset,
    })
    return param
    // return Replace(params).sql; //只使用sql
  } else {
    //生产环境
    return devParam(params)
  }
}
export default {
  linku_ems(param) {
    const createsql = {
      param: {
        domain_id: domainid(),
      },
    }
    let data = ProPara(deepMerge(createsql, param))
    return axios({
      method: 'post',
      url: 'tsdb/linku_ems?format=series',
      data: data,
    })
  },
  linku_dict(params) {
    // 描述
    const createsql = {
      param: {
        domain_id: domainid(),
      },
    }
    let data = ProPara(deepMerge(createsql, params))
    return axios({
      method: 'post',
      url: 'tsdb/linku_ems?format=dict',
      data: data,
    })
  },
  //设备iot_id
  getIot_id(params) {
    return axios({
      method: 'get',
      url: `operation/${domainid()}/device`,
      params: {
        tag: 'energy_subsystem',
        ...params,
      },
    })
  },
  //策略管理
  pv_ess_pcs_balance() {
    return axios({
      method: 'get',
      url: `operation/${domainid()}/pv_ess_pcs_balance`,
    })
  },

  //字典
  Dictionary(param, type) {
    //参数iot_id,还有设备类型
    let url = ''
    if (type == 'iot_id') {
      if (!param) return
      url = `thing_model/device/${param}/property_dict`
    } else {
      url = `thing_model/category/${param}/property_dict`
    }
    return axios({
      method: 'get',
      url,
    })
  },
  //获取单元
  datapool(params, app) {
    //获取查询单元
    // "domainid":"DXmhMbmmbNo5jqIewvY",
    // "parent_id":"DrccYolYM54ycDN3lBp--__LQThCyUrLyrovMpU6wC",
    // "category": "energy_subsystem",
    // "subcategory": "光伏"
    //查询设备
    // "domainid": "qg5W9vRjldlzXAOfF3h",
    // "tag_subcategory": "'光伏'",
    //  tag_id: 单元返回的id
    let data = ProPara(deepMerge({ param: params }, app))
    return axios({
      method: 'post',
      url: '/datapool/mysql?format=row',
      data: data,
    })
  },
  // 多个设备分时段合计汇总
  tsdbSearchseries(params, name) {
    let data = ProPara(
      deepMerge(
        { param: { ...params, domain_id: domainid() } },
        { name, application: 'linku_ems', datasource: 'ems_tsdb' }
      )
    )
    return axios({
      method: 'post',
      url: '/datapool/tsdb?format=series',
      data: data,
    })
  },
  // 时许数据查询
  tsdbSearch(params, name) {
    let data = ProPara(
      deepMerge(
        { param: { ...params, domain_id: domainid() } },
        { name, application: 'linku_ems', datasource: 'ems_tsdb' }
      )
    )
    return axios({
      method: 'post',
      url: '/datapool/tsdb?format=dict',
      data: data,
    })
  },
}
