// 实时监控
export default {
  path: '/Monitor',
  component: () => import('views/Layout.vue'),
  children: [
    {
      path: 'FaultAlarm',
      name: 'FaultAlarm',
      component: () => import('views/realtimeMonitor/faultAlarm.vue'),
      meta: {
        _meauname: '故障告警',
        keepAlive: true,
      },
    },
    {
      path: 'DeviceMonitor',
      name: 'DeviceMonitor',
      component: () => import('views/realtimeMonitor/deviceMonitor.vue'),
      meta: {
        _meauname: '实时运行',
        keepAlive: true,
      },
    },
    {
      path: 'SiteMap',
      name: 'SiteMap',
      component: () =>
        import(/*webpackChunkName: "SiteMap"*/ 'views/marketData/siteMap'),
      meta: {
        _meauname: '站点地图',
        keepAlive: true,
      },
    },
    {
      path: 'AnOverviewTheSite',
      name: 'AnOverviewTheSite',
      component: () =>
        import(
          /*webpackChunkName: "AnOverviewTheSite"*/ 'views/marketData/siteOverview'
        ),
      meta: {
        _meauname: '多站概览',
        keepAlive: true,
      },
    },
    {
      path: '/:pathMatch(.*)',
      component: () => import('views/404'),
      meta: {
        _meauname: '实时监控404',
      },
    },
  ],
}
