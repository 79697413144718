// 系统管理
export default {
  path: '/System',
  component: () => import('views/Layout.vue'),
  children: [
    {
      path: 'SiteManager',
      name: 'SiteManager',
      component: () =>
        import(/*webpackChunkName: "SiteManager"*/ 'views/system/siteManager'),
      meta: {
        _meauname: '站点管理',
        keepAlive: true,
      },
    },
    {
      path: 'LogManagement',
      name: 'LogManagement',
      component: () =>
        import(/*webpackChunkName: "LogManagement"*/ 'views/system/logs'),
      meta: {
        _meauname: '日志管理',
        keepAlive: true,
      },
    },
    {
      path: 'AccountManagement',
      name: 'AccountManagement',
      component: () =>
        import(
          /*webpackChunkName: "AccountManagement"*/ 'views/system/account'
        ),
      meta: {
        _meauname: '账号管理',
        keepAlive: true,
      },
    },
    {
      path: 'RoleManagement',
      name: 'RoleManagement',
      component: () =>
        import(/*webpackChunkName: "roles"*/ 'views/system/roles'),
      meta: {
        _meauname: '角色管理',
        keepAlive: true,
      },
    },
    {
      path: 'PersonalityConfiguration',
      name: 'PersonalityConfiguration',
      component: () =>
        import(
          /*webpackChunkName: "personalConfig"*/ 'views/system/personalConfig'
        ),
      meta: {
        _meauname: '个性配置',
        keepAlive: true,
      },
    },
    // {
    //   path: 'standardModel',
    //   name: 'standardModel',
    //   redirect: '/System/standardModel/list',
    //   component: () =>
    //     import(
    //       /*webpackChunkName: "physicalModelManagement"*/ 'views/system/models/standardModel/index'
    //     ),
    //   meta: {
    //     _meauname: '标准模型管理',
    //     // keepAlive: true,
    //   },
    // },
    // {
    //   path: 'physicalModel',
    //   name: 'physicalModel',
    //   redirect: '/System/physicalModel/list',
    //   component: () =>
    //     import(
    //       /*webpackChunkName: "physicalModelManagement"*/ 'views/system/models/physicalModel/index'
    //     ),
    //   meta: {
    //     _meauname: '物模型管理',
    //     // keepAlive: true,
    //   },
    // },
    {
      path: '/:pathMatch(.*)',
      component: () => import('views/404'),
      meta: {
        _meauname: '系统管理404',
      },
    },
  ],
}
