/*
 * @Author: 刘彭涛 157511383@qq.com
 * @Date: 2022-04-09 14:05:47
 * @LastEditors: 刘彭涛 157511383@qq.com
 * @LastEditTime: 2022-08-27 15:14:10
 * @FilePath: \zero-c-cloud\src\server\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from '../utils/aioxs'
import { domainid } from '@/utils/utils'
export default {
  checkin(loginForm) {
    // 登录
    return axios({
      method: 'post',
      url: '/signon/api/runtime/login/checkin',
      data: loginForm,
    })
  },
  changeCheckcodeImg() {
    // 更改验证图案
    return axios({
      method: 'post',
      url: '/signon/api/runtime/login/changeCheckcodeImg',
    })
  },

  changesignin(loginForm) {
    // 选择域
    return axios({
      method: 'post',
      url: '/signon/api/runtime/login/signin',
      data: loginForm,
    })
  },
  myprofile(loginForm) {
    // 个人信息
    return axios({
      method: 'get',
      url: '/obpm/api/runtime/users/myprofile',
      params: loginForm,
    })
  },
  applications() {
    // 获取系统设置
    return axios({
      method: 'get',
      url: '/obpm/api/runtime/applications',
    })
  },
  getMenus(id) {
    // 侧边栏
    return axios({
      method: 'get',
      url: `/obpm/api/runtime/${id}/menus`,
    })
  },
  logout() {
    // 退出
    return axios({
      method: 'post',
      url: `/obpm/api/runtime/logout`,
    })
  },
  convert(params) {
    return axios({
      method: 'get',
      url: `/v3/assistant/coordinate/convert`,
      requestBaseUrl: 'restapi',
      params: Object.assign(
        {
          key: '195b56469ebfee5f34ab067663052d78',
          coordsys: 'baidu',
        },
        params
      ),
    })
  },
  weather(params) {
    // 天气
    return axios({
      method: 'get',
      url: `/v3/weather/weatherInfo`,
      requestBaseUrl: 'restapi',
      params: Object.assign(
        {
          key: '195b56469ebfee5f34ab067663052d78',
          extensions: 'base',
        },
        params
      ),
    })
  },
  CityinverseAnalysis(params) {
    //传入经纬度
    // location 经度在前，纬度在后，经纬度间以“,”分割
    // 地址逆解析
    return axios({
      method: 'get',
      url: `/v3/geocode/regeo`,
      requestBaseUrl: 'restapi',
      params: Object.assign(
        {
          key: '195b56469ebfee5f34ab067663052d78',
        },
        params
      ),
    })
  },
  //视频监控
  videoList(stationId, type = 'device') {
    return axios({
      method: 'get',
      url: `/operation/${domainid()}/${stationId}/video/list`,
      params: {
        type,
      },
    })
  },

  //视频监控地址
  videoUrl(stationId, device_id) {
    return axios({
      method: 'get',
      url: `/operation/${domainid()}/${stationId}/video/device/url`,
      params: {
        device_id,
      },
    })
  },
  //监控配置
  MonitoringControl(stationId, params) {
    return axios({
      method: 'get',
      url: `/operation/${domainid()}/${stationId}/video/device/ptz`,
      params: Object.assign(
        {
          action: 'continuous_move',
        },
        params
      ),
    })
  },
  //图片上传
  pictureupload(datas) {
    return axios({
      method: 'post',
      url: `/oss/upload/picture`,
      data: datas
    })
  },
}
