import { ElLoading } from 'element-plus';
let loadingInstance;
const startLoading=()=> {
   loadingInstance = ElLoading.service({
    lock: true,
    text: "正在加载...",
    background:'rgba(122, 122, 122, 0.8)',
    target: document.querySelector('.loading-area') //设置加载动画区域
  })
}

const endLoading=()=>{
  loadingInstance.close();
}

const lockScroll=(selector, isLock = false)=>{
  let scrollWrap = document.querySelector(selector);
  if(!scrollWrap) return
  let hasClass = scrollWrap.classList.contains("lock-scroll");

  if (isLock) {
    !hasClass && scrollWrap.classList.add("lock-scroll");
    scrollWrap.scrollTo(0, 0);
  } else {
    hasClass && scrollWrap.classList.remove("lock-scroll");
  }
}

let needLoadingRequestCount = 0;
export const  showFullScreenLoading=()=> {
  if (needLoadingRequestCount === 0) {
    lockScroll(".loading-area", true);
    startLoading();
  }
  needLoadingRequestCount++;
};
export const HideFullScreenLoading=()=> {
  if (needLoadingRequestCount <= 0) return;
  needLoadingRequestCount--;
  if (needLoadingRequestCount === 0) {
    lockScroll(".loading-area");
    endLoading();
  }
};