/*
 * @Author: 刘彭涛 157511383@qq.com
 * @Date: 2022-04-08 16:03:25
 * @LastEditors: 刘彭涛 157511383@qq.com
 * @LastEditTime: 2022-08-26 11:30:25
 * @FilePath: \zero-c-cloud\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
// import '@/assets/iconfont/iconfont.css'
import axios from './utils/aioxs'
import './styles/element/index.scss'
import './styles/index.scss'
import 'xe-utils'
import VXETable from 'vxe-table'
import VXETablePluginMenus from 'vxe-table-plugin-menus'
import 'vxe-table/lib/style.css'
import vue3videoPlay from 'vue3-video-play' // 引入组件
import 'vue3-video-play/dist/style.css' // 引入css
VXETable.use(VXETablePluginMenus)
const app = createApp(App)
app.config.globalProperties.$axios = axios
import zhCn from 'element-plus/es/locale/lang/zh-cn'
// 自定义指令1
import * as directives from '@/directives'
Object.keys(directives).forEach((key) => {
  app.directive(key, directives[key])
})

app
  .use(ElementPlus, { locale: zhCn })
  .use(store)
  .use(VXETable)
  .use(router)
  .use(vue3videoPlay)
  .mount('#app')
