import { createStore } from 'vuex'
import SocketPlugin from '@/server/socket/webSoket'
import { useRoute } from 'vue-router'
import router, { routerList } from '../router'
import API from '@/server/index.js'
import { getMenuList, AddRoute } from './config.js'
import { childRouter } from 'router/childRouter'
export default createStore({
  state: {
    activeValue: [],
    activeIndex: {},
    activeSiteId: '',
    socket: null,
    keepLive: [],
    SiteInformation: {}, //站点信息
    MenuList: [],
    activePath: '',
    modeData: {}, //物模型加载的数据
    modeErrorAlarm: [], //无模型输入以后的告警信息
  },
  getters: {
    getActive(state) {
      return state.activeValue
    },
    getActiveIndex(state) {
      return state.activeIndex
    },
    getActiveSiteId(state) {
      return state.activeSiteId
    },
    getSocket(state) {
      return new Promise((resolve, inject) => {
        let timeout = setInterval(() => {
          if (state.socket) {
            resolve(state.socket)
            clearInterval(timeout)
          }
        }, 100)
      })
    },
    getkeepLive(state) {
      return state.keepLive
    },
    getSiteInformation(state) {
      //获取站点信息
      return state.SiteInformation
    },
    getMenu(state) {
      return state.MenuList
    },
    getActivePath(state) {
      return state.activePath
    },
    getModeData(state) {
      return state.modeData
    },
    getModeError(state) {
      return state.modeErrorAlarm
    },
  },
  mutations: {
    setActive(state, info) {
      let status = state.activeValue.some((item) => {
        return item.path == info.path || item.name == info.name
      })
      if (!status) state.activeValue.push(info)
    },
    removeActive(state, info) {
      let newActive = state.activeValue.filter((item) => {
        return item.name != info
      })
      state.activeValue = newActive
    },
    setActiveIndex(state, info) {
      state.activeIndex = info
    },
    setActiveSiteId(state, info) {
      state.activeSiteId = info
    },
    removeAllActive(state) {
      state.activeValue = []
    },
    setSocket(state, info) {
      state.socket = info
    },
    setkeepLive(state, info) {
      if (!state.keepLive.includes(info)) {
        state.keepLive.push(info)
      }
    },
    delKeepLive(state, info) {
      if (state.keepLive.includes(info)) {
        let index = state.keepLive.indexOf(info)
        state.keepLive.splice(index, 1)
      }
    },
    setSiteInformation(state, info) {
      //设置站点信息
      state.SiteInformation = info
    },
    clearState(state) {
      state.activeValue = []
      state.activeSiteId = ''
      state.keepLive = []
      state.SiteInformation = {}
      state.MenuList = []
      if (state.socket) {
        state.socket.close()
      }
    },
    setMenu(state, info) {
      state.MenuList = info
    },
    setActivepath(state, info) {
      state.activePath = info
    },
    setModeData(state, info) {
      state.modeData = info
    },
    setModeError(state, info) {
      state.modeErrorAlarm = info
    },
  },
  actions: {
    setActive(context, data) {
      context.commit('setActive', data)
    },
    removeActive(context, data) {
      context.commit('removeActive', data)
    },
    setActiveIndex(context, data) {
      context.commit('setActiveIndex', data)
    },
    setActiveSiteId(context, data) {
      context.commit('setActiveSiteId', data)
    },
    removeAllActive(context) {
      context.commit('removeAllActive')
    },
    setkeepLive(context, data) {
      context.commit('setkeepLive', data)
    },
    delKeepLive(context, data) {
      context.commit('delKeepLive', data)
    },
    async createSocket(context, data) {
      let socket = null
      socket = new SocketPlugin()
      await socket.connect()
      context.commit('setSocket', socket)
    },

    setSiteInformation(context, data) {
      //设置站点信息
      context.commit('setSiteInformation', data)
    },
    clearState(context, data) {
      context.commit('clearState')
    },
    setActivepath(context, data) {
      context.commit('setActivepath', data)
    },
    setModeData(context, data) {
      context.commit('setModeData', data)
    },
    setModeError(context, data) {
      context.commit('setModeError', data)
    },
    async setMenu(context) {
      const { id } = JSON.parse(localStorage.getItem('ationsInfo'))[0]
      const res = await API.getMenus(id)
      try {
        let data = res.data.data.filter((item) => item.name !== '运营管理')
        data = data.map((item) => {
          return {
            ...item,
            children: item.children.filter((val) => val.name !== '空调系统图'),
          }
        })
        let MenuList = []
        const MenuArray = await getMenuList(data, MenuList)
        context.commit('setMenu', MenuArray)
        let dynamicList = []
        const dynamicRouting = await AddRoute(
          MenuArray,
          routerList,
          dynamicList
        )
        dynamicRouting.forEach((element) => {
          router.addRoute(element)
        })
        childRouter.forEach((item) => {
          const { parentName, ...otherParameters } = item
          router.addRoute(parentName, otherParameters)
        })
      } catch (error) {}
    },
  },
})
