export const focus = {
  mounted(el, binding) {
    const { value } = binding
    if (
      (typeof value !== 'function' && value) ||
      (typeof value === 'function' && value())
    ) {
      el.querySelector('input')?.focus()
    }
  },
}
