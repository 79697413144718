<!--
 * @Author: 刘彭涛 157511383@qq.com
 * @Date: 2022-04-08 16:03:25
 * @LastEditors: 刘彭涛 157511383@qq.com
 * @LastEditTime: 2022-08-30 16:44:36
 * @FilePath: \zero-c-cloud\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <router-view />
</template>

<script setup>
import { useStore } from 'vuex'
const store = useStore()
//  store.dispatch('setMenu') //存储菜单
</script>

<style lang="scss">
@import './styles/theme/index.css';
* {
  padding: 0;
  margin: 0;
  font-size: 14px;
}
</style>
