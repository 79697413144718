//menu处理，处理接口直接返回的数据
// menuTreeList  需要处理的数据
// list 处理完要存放的变量
const getMenuList = async (menuTreeList, MenuList) => {
  menuTreeList.forEach((element, index) => {
    try {
      let newObject = {}
      let datas = JSON.parse(element.queryString)
      datas.forEach((item) => {
        newObject[item.paramKey] = item.paramValue
      })
      if (element.children) {
        MenuList.push({
          label: newObject.name,
          icon: `${newObject.icon}`,
          path: newObject.path, // 暂定
          children: [],
        })
        getMenuList(element.children, MenuList[index].children)
      } else {
        MenuList.push({
          label: newObject.name,
          icon: `${newObject.icon}`,
          path: newObject.path, // 暂定
        })
      }
    } catch (error) { }
  })
  return MenuList
}

// 动态路由
//menu 返回的菜单
//beforrouter  所有路由
//dynamicList 新的路由
const AddRoute = async (menu, beforrouter, dynamicList) => {
  menu.forEach((menuItem, index) => {
    let menuItemPath = menuItem.path.replace('/', '')
    let sq = beforrouter.findIndex((routerItem) => {
      return menuItemPath == routerItem.path.replace('/', '')
    })
    if (sq >= 0) {
      const { children, ...otherParame } = beforrouter[sq]
      if (menuItem.children) {
        dynamicList.push({
          ...otherParame,
          children: [],
        })
        AddRoute(menuItem.children, children, dynamicList[index].children)
      } else {
        dynamicList.push(otherParame)
      }
    }

  })
  return dynamicList
}
export { getMenuList, AddRoute }
