<!--
 * @Author: 刘彭涛 157511383@qq.com
 * @Date: 2022-04-09 11:50:42
 * @LastEditors: 刘彭涛 157511383@qq.com
 * @LastEditTime: 2022-08-30 15:55:21
 * @FilePath: \zero-c-cloud\src\components\layout\tasps.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-tabs
    ref="tabs"
    v-model="tapActive"
    type="card"
    closable
    class="systerm"
    @tab-click="changeTab"
    @tab-remove="removeTab"
  >
    <el-tab-pane
      v-for="item in stateData"
      :key="item.name"
      :label="item.name"
      :name="item.name"
      class="gva-tab"
    />
  </el-tabs>
</template>
<script setup>
import { reactive, computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
const router = useRouter()

const reactiveDate = reactive({
  contextMenuVisible: false,
})
const store = useStore()
const tabs = ref()
const stateData = computed(() => store.getters.getActive)

const tapActive = computed({
  get() {
    return store.getters.getActiveIndex
  },
  set(val) {
    store.dispatch('setActiveIndex', val)
  },
})
const changeTab = (value) => {
  let active = stateData.value.filter((item) => {
    return item.name == value.props.name
  })
  let { path, name } = active[0]
  router.push({ path: path })
  store.dispatch('setActiveIndex', name)
}

const emits = defineEmits(['remove'])
const removeTab = (tab) => {
  const findOne = stateData.value.find((item) => item.name === tab)
  emits('remove', findOne.routeName)
  if (stateData.value.length <= 1) return
  // 删除标签
  store.dispatch('removeActive', tab)
  if (tapActive.value == tab) {
      store.dispatch(
        'setActiveIndex',
        stateData.value[stateData.value.length - 1].name
      )
      router.push({ path: stateData.value[stateData.value.length - 1].path })
  }
}
const showClosa = () => {}
const AllClosa = () => {}
</script>

<style scoped lang="scss">
:deep(.el-tabs__header) {
  margin: 0;
  border-bottom-color: var(--line-color) !important;
}
</style>
