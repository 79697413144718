// 数据大盘
export default {
  path: '/MarketData',
  component: () => import('views/Layout.vue'),
  // DataScreen
  children: [
    {
      path: 'DataScreen',
      name: 'DataScreen',
      component: () =>
        import(
          /*webpackChunkName: "DataScreen"*/ 'views/marketData/dataScreen'
        ),
      meta: {
        _meauname: '数据大屏',
        keepAlive: true,
      },
    },
    {
      path: '/:pathMatch(.*)',
      component: () => import('views/404'),
      meta: {
        _meauname: '数据大盘404',
      },
    },
  ],
}
