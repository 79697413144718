/*
 * @Author: 刘彭涛 157511383@qq.com
 * @Date: 2022-04-08 16:03:25
 * @LastEditors: 刘彭涛 157511383@qq.com
 * @LastEditTime: 2022-10-26 14:15:07
 * @FilePath: \zero-c-cloud\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'
import Layout from 'views/Layout.vue'
const routerList = [] //路由集合
const inporantall = (r) => {
  r.keys().forEach((key) => {
    if (r(key).default) {
      routerList.push(r(key).default)
    }
  })
}
inporantall(require.context('./module', true, /\.js/))
const routes = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    redirect: '/Monitor/SiteMap',
    children: [
      {
        path: 'home',
        name: 'home',
        redirect: '/Monitor/SiteMap',
        component: () =>
          import(/*webpackChunkName: "home"*/ 'views/home/index'),
        meta: {
          _meauname: '主页',
        },
      },
      {
        path: 'logicOperation',
        name: 'logicOperation',
        component: () =>
          import(
            /*webpackChunkName: "home"*/ 'views/singleSiteMonitor/siteSettings/deviceManagement/components/test.vue'
          ),
        meta: {
          _meauname: '逻辑运算',
        },
      },
      {
        path: '/:pathMatch(.*)',
        component: () => import('views/404'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/*webpackChunkName: "login"*/ 'views/login.vue'),
  },
  {
    path: '/mobile',
    name: 'mobile',
    component: () => import(/*webpackChunkName: "login"*/ '@/Mobile/index.vue'),
  },
  {
    path: '/401',
    name: '401',
    component: () => import(/*webpackChunkName: "401"*/ 'views/401'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/*webpackChunkName: "404"*/ 'views/404'),
  },
]
const router = createRouter({
  history: createWebHashHistory(),
  routes,
})
//浏览器回退
router.beforeEach((to, from, next) => {
  let userinfo = JSON.parse(localStorage.getItem('userInfo'))
  let ations = JSON.parse(localStorage.getItem('ationsInfo'))
  let token = localStorage.getItem('checkInToken')
  let domainId = userinfo ? userinfo.data.domainId : null
  let ationsID = ations ? (ations[0] ? ations[0].id : null) : null
  if (to.path == '/login') {
    // localStorage.clear()
    store.dispatch('clearState')
    next()
  } else {
    if (token && domainId && ationsID) {
      if (to.meta.children) {
        store.dispatch('setActivepath', to.matched[1].path)
      }
      if (to.meta._meauname) {
        store.dispatch('setActive', {
          path: to.path,
          name: to.meta._meauname,
          routeName: to.name,
        })
        store.dispatch('setActiveIndex', to.meta._meauname) //tap名称
      }
      if (store.getters.getMenu.length) {
        next()
      } else {
        store.dispatch('setMenu').then((res) => {
          next({ ...to, replace: true })
        })
      }
    } else {
      if (to.path != '/mobile') {
        let query = {}
        if (domainId === 'DKseGL0xtdHO61iFCoz') {
          query.redirect = to.path
          query.domainId = domainId
          query.autologin = true
        }
        next({ path: '/login', replace: true, query })
        // localStorage.clear()
        store.dispatch('clearState')
      } else {
        next()
      }
    }
  }
})
export { routerList }

export default router
