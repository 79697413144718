export default {
  path: '/development',
  component: () => import('views/Layout.vue'),
  children: [
    {
      path: 'PolicyManagement',
      name: 'PolicyManagement',
      component: () =>
        import('views/singleSiteMonitor/policyManagement/index.vue'),
      meta: {
        _meauname: '策略管理',
        keepAlive: true,
      },
    },
    {
      path: 'DeviceDevelopment',
      name: 'DeviceDevelopment',
      component: () =>
        import('views/singleSiteMonitor/deviceDevelopment/index.vue'),
      meta: {
        _meauname: '微网调试',
        keepAlive: true,
      },
    },
    {
      path: 'standardModel',
      name: 'standardModel',
      redirect: '/development/standardModel/standardModellist',
      component: () =>
        import(
          /*webpackChunkName: "physicalModelManagement"*/ 'views/system/models/standardModel/index'
        ),
      meta: {
        _meauname: '标准模型管理',
        // keepAlive: true,
      },
    },
    {
      path: 'physicalModel',
      name: 'physicalModel',
      redirect: '/development/physicalModel/physicalModellist',
      component: () =>
        import(
          /*webpackChunkName: "physicalModelManagement"*/ 'views/system/models/physicalModel/index'
        ),
      meta: {
        _meauname: '物模型管理',
        // keepAlive: true,
      },
    },
  ]
}