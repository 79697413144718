<template>
  <div class="common-layout" ref="conte">
    <el-container style="height: 100%">
      <el-header>
        <div class="fcsb">
          <div class="fcl left">
            <div class="logoLeft fcc">
              <div class="log fssb fcc">
                <img :src="dataJson.logoImg.url" />
                <div class="line"></div>
                <div class="systerm fcolumncenter">
                  <div>{{ dataJson.system_name }}</div>
                  <!-- <div class="font-size:16px">qhww system</div> -->
                </div>
              </div>
            </div>

            <div
              class="iconfont Switch upmenu width100"
              :class="[start ? 'icon-shouqi' : 'icon-zhankai']"
              @click="Toggle"
            />
            <div v-if="MenuList.length">
              <el-menu
                class="el-menu-demo headerMenu"
                mode="horizontal"
                :ellipsis="false"
                :default-active="SelectData.path"
                text-color="#fff"
                active-text-color="#ffd04b"
              >
                <el-menu-item
                  class="upmenu width150"
                  v-for="(el, index) in MenuList"
                  :key="index"
                  :index="el.path"
                  @click="handleSelect(el)"
                >
                  <i
                    :class="el.icon"
                    class="iconfont"
                    style="font-size: 16px; margin-right: 10px"
                  />
                  <template #title>{{ el.label }}</template>
                </el-menu-item>
              </el-menu>
            </div>
          </div>

          <div class="fcr right signOut" v-if="SelectData.lives.weather">
            <span style="padding-right: 10px; color: #fff"
              >{{ SelectData.lives.weather }}/{{
                SelectData.lives.temperature
              }}℃</span
            >

            <el-tooltip placement="bottom">
              <template #content>
                城市：{{ SelectData.lives.province + SelectData.lives.city }}
                <br />
                天气状况：{{ SelectData.lives.weather }}
                <br />
                实时气温：{{ SelectData.lives.temperature }}
                <br />
                空气湿度：{{ SelectData.lives.humidity }}
                <br />
                风力级别：{{ SelectData.lives.windpower }}
                <br />
                风向：{{ SelectData.lives.winddirection }}
                <br />
              </template>
              <i
                :class="SelectData.icon"
                class="iconfont icon"
                v-if="SelectData.icon"
              />
            </el-tooltip>
            <!-- <el-switch v-model="theme" @change="changeTheme" /> -->
            <el-tooltip content="换肤">
              <div @click="changeTheme" class="user">
                <i class="iconfont icon-qiehuan"></i>
              </div>
            </el-tooltip>
            <el-tooltip content="全屏">
              <div class="user" @click="quanping">
                <i class="iconfont icon-fangda" style="font-size: 14px"></i>
              </div>
            </el-tooltip>
            <el-tooltip content="退出">
              <div class="user" @click="logout">
                <i class="iconfont icon-a-tuichu1"></i>
              </div>
            </el-tooltip>
          </div>
          <el-tooltip content="退出" v-else>
            <div class="user" @click="logout">
              <i class="iconfont icon-a-tuichu1"></i>
            </div>
          </el-tooltip>
        </div>
      </el-header>
      <el-container style="height: calc(100% - 60px)">
        <el-aside width="collapse">
          <template v-if="SelectData.data.length">
            <Menu
              :is-collapse="start"
              :data-list="SelectData"
              :default-active="defaultActive"
            />
          </template>
        </el-aside>
        <el-main class="loading-area">
          <app-main />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script setup>
import Menu from '@/components/layout/menu.vue'
import appMain from 'views/AppMain'
import { useRouter, useRoute } from 'vue-router'
import {
  ref,
  onMounted,
  reactive,
  computed,
  watch,
  watchEffect,
  unref,
} from 'vue'
import API from '../server/index.js'
import { getSites, get_secondPassword } from '@/server/site'
import { api as fullscreen } from 'vue-fullscreen'
import { FullScreen } from '@element-plus/icons-vue'
import { useStore } from 'vuex'
import { deepMergeObject } from 'utils/utils'

const FullscreenState = ref()
const store = useStore()
const theme = ['default', 'black', 'green'] //主题切换
let index = 0
// 全屏
const quanping = () => {
  fullscreen.toggle(document.querySelector('#app'), {
    teleport: true,
    callback: (isFullscreen) => {
      FullscreenState.value = isFullscreen
      console.log(isFullscreen)
    },
  })
}
const changeTheme = () => {
  console.log(index)
  if (index < theme.length - 1) {
    index++
    localStorage.setItem('theme', index)
    document.documentElement.setAttribute('theme', theme[index])
  } else {
    index = 0
    localStorage.setItem('theme', index)
    document.documentElement.removeAttribute('theme')
  }
}
const MenuList = ref([])
const start = ref(false)
const router = useRouter()
const route = useRoute()
const Toggle = () => {
  start.value = !start.value
}
const defaultActive = ref('')
const siteList = ref([])
const activeSiteId = ref()
const SelectData = reactive({
  path: '/System',
  data: [],
  icon: '',
  lives: {},
})
const getSiteInformation = computed(() => store.getters.getSiteInformation) //全部
let userInfo = localStorage.getItem('userInfo')
userInfo = JSON.parse(userInfo)
//获取缓存
watch(
  () => router.currentRoute.value.path,
  (newValue, oldValue) => {
    if (newValue != '/home') {
      let routeList = newValue.split('/').filter((item) => item != '')
      SelectData.path = '/' + routeList[0]
      MenuList.value.forEach((item) => {
        if (item.path == SelectData.path) {
          SelectData.data = item.children ? item.children : [item]
        }
      })
      defaultActive.value = newValue
    }
  },
  { immediate: true }
)
const setActiveSite = async () => {
  try {
    const res = await getSites({ include_hide: 0 })
    if (res.data && res.data.msg) {
      siteList.value = res.data.msg
      let activeId = localStorage.getItem('activeSiteId')
      if (activeId) {
        const findOne = siteList.value.find((item) => item.id === activeId)
        if (!findOne) {
          activeId = siteList.value[0].id
          store.dispatch('setSiteInformation', siteList.value[0]) //储存站点信息
        } else {
          store.dispatch('setSiteInformation', findOne) //储存站点信息
        }
      } else {
        activeId = siteList.value[0].id
        store.dispatch('setSiteInformation', siteList.value[0]) //储存站点信息
      }
      activeSiteId.value = activeId
      store.dispatch('setActiveSiteId', activeId)
      localStorage.setItem('activeSiteId', activeId)
    }
  } catch (error) {
    console.log(error)
  }
}
const reverseAnalysis = async (param) => {
  //地址逆向解析
  try {
    const coordformation = await API.convert({ locations: param.location }) //经纬度纠偏
    const res = await API.CityinverseAnalysis({
      location: coordformation.data.locations,
    }) //经纬度解地址信息
    getWeather(res.data.regeocode.addressComponent.adcode)
  } catch (error) {
    console.log(error)
  }
}
watchEffect(() => {
  const id = getSiteInformation.value.id
  if (id) {
    //如果有站点id
    reverseAnalysis({
      location: `${getSiteInformation.value.item_longitude},${getSiteInformation.value.item_latitude}`,
    })
  } else {
    setActiveSite() //如果没有设置站点，则进行站点初始化
  }
})

const getMenus = async () => {
  const menulist = store.getters.getMenu
  MenuList.value = menulist
  const list = JSON.parse(localStorage.getItem('ationsInfo')).filter(
    (value) => {
      return value.activated
    }
  )
  let routeList = route.path.split('/').filter((item) => item != '') //路由
  let Status = menulist.some((item) => {
    return item.path == '/' + routeList[0]
  })
  if (Status) {
    //路由筛选项
    menulist.forEach((item) => {
      if (item.path == '/' + routeList[0]) {
        SelectData.path = item.path
        SelectData.data = item.children ? item.children : [item]
      }
    })
  } else {
    menulist.forEach((item) => {
      if (item.path == '/System') {
        SelectData.path = '/System'
        SelectData.data = item.children ? item.children : [item]
      }
    })
  }
  //二级路由
  defaultActive.value = route.path
}
const logout = async () => {
  const res = await API.logout() //退出系统
  // router.replace({
  //   path: '/login',
  // })
  localStorage.clear()
  // reload
  router.go(0)
}
const handleSelect = (item) => {
  SelectData.path = item.path
  SelectData.data = item.children ? item.children : [item]
  router.push({
    path: SelectData.path + item.children[0].path,
  })
}
const iconWeatherMap = {
  'icon-wind': [
    '有风',
    '平静',
    '微风',
    '和风',
    '清风',
    '强风/劲风',
    '疾风',
    '大风',
    '烈风',
    '风暴',
    '狂爆风',
    '飓风',
    '热带风暴',
    '龙卷风',
  ], //风
  'icon-weather-2': ['少云', '晴间多云', '多云'], //多云
  'icon-weather-16': [
    '雪',
    '阵雪',
    '小雪',
    '中雪',
    '大雪',
    '暴雪',
    '小雪-中雪',
    '中雪-大雪',
    '大雪-暴雪',
    '冷',
  ], //雪
  'icon-weather-19': [
    '浮尘',
    '扬沙',
    '沙尘暴',
    '强沙尘暴',
    '雾',
    '浓雾',
    '强浓雾',
    '轻雾',
    '大雾',
    '特强浓雾',
  ], //雾
  'icon-weather-1': ['晴', '热'], //晴
  'icon-yuxue': ['雨雪天气', '雨夹雪', '阵雨夹雪'], //雨夹雪
  'icon-yu': [
    '阵雨',
    '雷阵雨',
    '雷阵雨并伴有冰雹',
    '小雨',
    '中雨',
    '大雨',
    '暴雨',
    '大暴雨',
    '特大暴雨',
    '强阵雨',
    '强雷阵雨',
    '极端降雨',
    '毛毛雨/细雨',
    '雨',
    '小雨-中雨',
    '中雨-大雨',
    '大雨-暴雨',
    '暴雨-大暴雨',
    '大暴雨-特大暴雨',
    '冻雨',
  ], //雨
  'icon-weather-3': ['阴', '霾', '中度霾', '重度霾', '严重霾', '未知'], //阴
}

//获取天气
const getWeather = async (code) => {
  try {
    const res = await API.weather({ city: code })
    if (res.data.infocode == '10000') {
      let name = res.data.lives[0].weather
      for (let item in iconWeatherMap) {
        if (iconWeatherMap[item].includes(name)) {
          SelectData.icon = item
          SelectData.lives = res.data.lives[0]
          break
        }
      }
    }
  } catch (error) {
    console.log(error)
  }
}

const init = async () => {
  await getMenus()
  // getMenu()
}
const dataJson = ref({
  logoImg: {
    url: '',
    fixedNumber: [4, 1],
  },
  iconImg: {
    url: '',
    fixedNumber: [1, 1],
  },
  system_name: '',
})
//查询
const query = async () => {
  const res = await get_secondPassword({
    parent_id: JSON.parse(localStorage.getItem('userInfo')).domainId,
    category: '系统管理',
    subcategory: '个性配置',
  })
  dataJson.value = {
    logoImg: {
      url: 'https://linku-file.oss-cn-beijing.aliyuncs.com/zero_c_cloud/output.lin.png',
      fixedNumber: [4, 1],
    },
    iconImg: {
      url: 'https://linku-file.oss-cn-beijing.aliyuncs.com/zero_c_cloud/favicon.ico',
      fixedNumber: [1, 1],
    },
    system_name: '系统名称',
  }
  if (res.data.code == 200) {
    try {
      let item_extra_content = '{}'
      if (res.data.msg.length) {
        item_extra_content = res.data.msg[0].item_extra_content
      }

      localStorage.setItem('themeuseInfo', item_extra_content)
      let dataObject = JSON.parse(item_extra_content)
      dataJson.value = deepMergeObject(unref(dataJson.value), dataObject)
    } catch (error) {
      console.log(error)
    }
    changeFavicon()
  }
}
const changeFavicon = () => {
  let favicon = document.querySelector('link[rel="icon"]')
  if (favicon !== null) {
    favicon.href = dataJson.value.iconImg.url
  } else {
    favicon = document.createElement('link')
    favicon.rel = 'icon'
    favicon.href = dataJson.value.iconImg.url
    document.head.appendChild(favicon)
  }
}

onMounted(async () => {
  store.dispatch('createSocket')
  query()
  let localStoragetheme = localStorage.getItem('theme')
  if (localStoragetheme - 0) {
    document.documentElement.setAttribute('theme', theme[localStoragetheme - 0])
    index = localStoragetheme - 0
  } else {
    // document.documentElement.removeAttribute('theme')
    document.documentElement.setAttribute('theme', theme[1])
    index = 1
  }
  init()
})
</script>

<style lang="scss" scoped>
:deep(.el-header) {
  height: auto;
  --el-header-padding: 0;
  border-bottom: 1px solid var(--line-color);
  margin-bottom: 15px;
  background: var(--header-background-color);
}

.el-aside {
  border: 1px solid var(--line-color) !important;
  // background: var(--header-background-color);
  background: #1c1e24;
}
.signOut {
  background: var(--loginright-imgurl);
  height: 63px;
  width: 290px;
  background-size: cover;
}

.user {
  color: #fff;
  cursor: pointer;
  margin-right: 10px;
  i {
    margin-right: 7px;
  }
}
.fcsb {
  .logoLeft {
    height: 63px;
    width: 416px;
    background: var(--logo-imgurl);
    background-size: 100% 100%;
  }
}

.el-main {
  padding: 0 !important;
  margin-left: 10px;
}
.icon {
  font-size: 40px;
  color: #fff;
  margin-right: 10px;
}
.left {
  .log {
    width: 300px;
    height: 35px;
    padding-right: 70px;
    img {
      height: 40px;
    }
    .line {
      margin: 0 10px;
      height: 15px;
      border: 1px solid #ffffff;
    }
    .systerm {
      // width: 77px;
      font-size: 10px;
      font-weight: 400;
      color: #ffffff;
      opacity: 0.7;
    }
  }

  .Switch {
    margin-left: 23px;
    font-size: 16px;
    color: #fbffff;
    text-align: center;
    height: 50px;
    line-height: 50px;
  }
}
.loading-area.lock-scroll {
  overflow: hidden;
  position: relative;
}
</style>
