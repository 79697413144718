// 数据报表
export default {
  path: '/DataReport',
  component: () => import('views/Layout.vue'),
  children: [
    {
      path: 'PowerStatements',
      name: 'PowerStatements',
      component: () => import('views/report/electricity.vue'),
      meta: {
        _meauname: '电量报表',
        keepAlive: true,
      },
    },
    {
      path: 'EarningsReport',
      name: 'EarningsReport',
      component: () => import('views/report/earnings.vue'),
      meta: {
        _meauname: '收益报表',
        keepAlive: true,
      },
    },
    {
      path: '/:pathMatch(.*)',
      component: () => import('views/404'),
      meta: {
        _meauname: '数据报表404',
      },
    },
  ],
}
