let childRouter=[{
    path: 'standardModellist',
    parentName:'standardModel',
    name:'standardModellist',
    component:()=>
      import(
        /*webpackChunkName: "physicalModelManagement"*/ 'views/system/models/standardModel/list'
      ),
      meta: {
        _meauname: '标准模型管理',
        children:true
        // keepAlive: true,
      },
  },
  {
    path: 'standardModeladd',
    parentName:'standardModel',
    name:'standardModeladd',
    component:()=>
      import(
        /*webpackChunkName: "physicalModelManagement"*/ 'views/system/models/standardModel/add'
      ),
      meta: {
        _meauname: '标准模型管理',
        children:true
        // keepAlive: true,
      },
  },{
    path: 'physicalModellist',
    parentName:'physicalModel',
    name:'physicalModellist',
    component:()=>
      import(
        /*webpackChunkName: "physicalModelManagement"*/ 'views/system/models/physicalModel/list'
      ),
      meta: {
        _meauname: '物模型管理',
        children:true
        
        // keepAlive: true,
      },
  },
  {
    path: 'physicalModeladd',
    parentName:'physicalModel',
    name:'physicalModeladd',
    component:()=>
      import(
        /*webpackChunkName: "physicalModelManagement"*/ 'views/system/models/physicalModel/add'
      ),
      meta: {
        _meauname: '物模型管理',
        children:true
        // keepAlive: true,
      },
  }]


  export {
    childRouter
  }