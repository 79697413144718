import API from './dataset'
import { domainid } from '@/utils/utils'

// 查询站点
export async function getSites(params) {
  const param = {
    domainid: domainid(),
    include_hide: 1,
    ...params,
  }
  return API.datapool(param, {
    application: 'linku_ems',
    datasource: 'ems_backend',
    name: 'energy_station_query',
  })
}

// 查询时段
export async function getTimesTemplateAPI(params) {
  const param = {
    domainid: domainid(),
    ...params,
  }
  return API.datapool(param, {
    application: 'linku_ems',
    datasource: 'ems_backend',
    name: 'time_range_template_query',
  })
}

// 查询电表时段
export async function getTimesAPI(params) {
  const param = {
    domainid: domainid(),
    ...params,
  }
  return API.datapool(param, {
    application: 'linku_ems',
    datasource: 'ems_backend',
    name: 'meter_time_range_query',
  })
}

//查询二次口令
export async function get_secondPassword(params) {
  const param = {
    domainid: domainid(),
    ...params,
  }
  return API.datapool(param, {
    application: 'linku_ems',
    datasource: 'ems_backend',
    name: 'second_password',
  })
}
